const locale = {
	ADD: 'Ajouter',
	ADDRESS: 'Sélectionner une adresse',
	ADD_ASSET_REFERENCE: 'Ajouter une référence',
	ADD_A_STEP: 'Ajouter une étape',
	STEP: 'Étape',
	ADD_TRANSPORT: 'Transport',
	COTATION: 'Cotation',
	AIRCRAFT: 'Aérien',
	ALERT: 'Alerte',
	ALERTS: 'Alertes',
	ALREADY_USED: 'est déjà utilisé',
	ARRIVAL: 'Arrivée',
	ASSET: 'Colis',
	ASSETS: 'Colis',
	ASSET_INFO: 'Informations à jour',
	ASSOCIATE_TRANSPORT: 'Transport associé',
	BATEAU: 'Maritime',
	BOUTON_CANCEL: 'Annuler',
	BOUTON_FINISHED: 'Finaliser',
	BOUTON_MODIFY: 'Modifier',
	BOUTON_RECYCLER: 'Recycler',
	BOUTON_SHARE: 'Copier lien',
	BOUTON_STOCKER: 'Stocker',
	VALIDATE: 'Valider',
	CLOSE_TRANSPORT: 'Archiver transport',
	ARCHIVE: 'Archiver',
	COMING_SOON: 'Bientôt disponible',
	CONTAINER_INFO: 'Informations sur le colis',
	COPIED_LINK: 'Lien copié dans le presse papier.',
	CREATED: 'Créé',
	CREATE_TRANSPORT: "Création d'un transport",
	CREATE_TRANSPORT_SUBTITLE1: 'Référence du transport',
	CREATE_TRANSPORT_SUBTITLE2: 'Associer votre transport à un ou des trackers',
	CREATE_TRANSPORT_SUBTITLE3: 'Référence colis',
	CREATE_TRANSPORT_SUBTITLE4: 'Ajouter un parcours',
	CREATE_TRANSPORT_SUBTITLE5: 'Spécifier les alertes du transport',
	CURRENT_ROUTE: 'Parcours',
	MAP: 'Carte',
	DEFAULT: 'Par défaut',
	DELETE_ASSET_TEXT:
		"Etes-vous sur de vouloir supprimer ce colis de votre transport ? Si oui, vous pouvez opter pour un recyclage du tracker s'il s'agit d'un usage unique ou d'une remise en stock si vous souhaitez l'utiliser ultérieurement pour un nouveau transport",
	DELETE_ASSET_TRANSPORT: "Suppression d'un colis",
	DELETE_A_STEP: 'Supprimer cette étape',
	DEPARTURE: 'Départ',
	DESTINATION: 'Destination',
	DESTINATION_END: 'Arrivée',
	DIALOG_FINALISE: 'Finaliser',
	DIALOG_NEXT: 'Suivant',
	DIALOG_PRECEDENT: 'Précédent',
	DOWNLOAD_CSV: 'Exporter données',
	EDIT: 'Modifier références',
	ERROR_ADD_TRANSPORT: 'Saisir une référence de transport',
	ERROR_MESSAGE_DIALOG3: 'est déjà utilisé comme référence de colis',
	ERROR_SELECT_TRACKER: 'Sélectionner au moins un tracker',
	ERROR_TRANSPORT_NAME: 'Cette référence est déjà utilisée pour un autre transport',
	ETAPE: 'Etape',
	ETAPES: 'Etapes',
	GEOFENCE: 'Aire de la géofence',
	GEOFENCING: 'Géofencing',
	HUMIDITY: 'Humidité',
	HUMIDITY_HISTORY: "Historique d'humidité",
	INCORRECT_ADDRESS: 'Adresse incorrecte',
	INFORMATION: 'Informations libres',
	LOADING_AVAILABLE_TRACKERS: 'Chargement des trackers disponibles',
	MINI_CARACTERE: '3 caractères minimum',
	MODIFY_ROUTE: 'Modifier le parcours',
	NOTIFICATIONS: 'Gérer notifications',
	NO_TRACKER_AVAILABLE: 'Aucun tracker disponible',
	POSITION: 'Position',
	RADIUS: 'Rayon',
	READY_TO_GO: 'Au départ',
	REFRESH: 'Rafraîchir',
	DELETE_ASSET: 'Supprimer colis',
	RIDE: 'Etape du parcours',
	SCANNER: 'Scanner',
	SEARCH_FOR_TRACKER: 'Rechercher un tracker',
	SEARCH_FOR_TRANSPORT: 'Rechercher...',
	SEARCH: 'Rechercher',
	SELECT_AN_ADDRESS: 'Sélectionner une adresse',
	SHARE_INFORMATIONS: 'Partager informations',
	SHARE: 'Partager',
	SHARED_LINK: 'Lien à partagé',
	SHARE_CHOSE_OPTION: 'Sélectionnez les données que vous souhaitez partager',
	SHARE_GENERATE_LINK: 'Générer lien',
	SHARE_TRACKER: 'Partager les informations du colis',
	SHARE_TRANSPORT: 'Partager les informations du transport',
	SIMILAR_POSITION: '# positions similaires',
	TEMPERATURE: 'Température',
	TEMPERATURE_HISTORY: 'Historique des températures',
	TEMPERATURE_HUMIDITY: 'Capteurs',
	TITLE: 'Titre',
	TO_COMPLETE: 'En préparation',
	TRACKER_LIST: 'Tracker(s) disponibles',
	TRANSIT: 'En transit',
	TRANSPORTS: 'transports',
	TRANSPORT: 'transport',
	TRANSPORT_ADD_FILTER: 'Filtrer',
	TRANSPORT_ENTER_NAME: 'Entrer une référence de transport',
	TRANSPORT_EXPLAIN_TEXT_1: "Définissez la référence unique vous permettant d'identifier ce transport",
	TRANSPORT_EXPLAIN_TEXT_2:
		"Sélectionnez dans la liste ou par la barre de recherche le numéro du tracker qui sera associé à ce transport. Répétez l'action autant de fois que vous avez de colis à tracker",
	TRANSPORT_EXPLAIN_TEXT_3:
		'Vous pouvez définir une référence unique pour chacun de vos colis associé à un tracker (optionnel)',
	TRANSPORT_EXPLAIN_TEXT_4:
		"Définissez les caractéristiques de votre transport afin d'être notifié tout au long du parcours. Les points de départ, d'arrivée et d'étapes sont définis par une zone géographique du rayon choisi autour de l'adresse sélectionnée",
	TRANSPORT_EXPLAIN_TEXT_5:
		'Sélectionnez les données de votre transport que vous souhaitez visualiser, ainsi que les alertes pour lesquelles vous serez notifié',
	TRANSPORT_HIDE_FILTERS: 'Masquer',
	TRANSPORT_TYPE: 'Mode de transport',
	TRUCK: 'Routier',
	UPDATE_ON: 'Mise à  jour le ',
	ROUTE: 'Parcours',
	WRITE_ASSET_REFERENCE: 'Entrer une référence de colis',
	DATE_PICKER_BEGIN: 'Début',
	DATE_PICKER_END: 'Fin',
	ETA_DATE_PICKER_BEGIN: 'Départ',
	ETA_DATE_PICKER_END: 'Arrivée',
	allAcces: 'Détails des colis',
	event: 'Evènements',
	humidity: 'Humidité',
	map: 'Géolocalisation',
	temperature: 'Température',
	TITLE_INFO: 'Informations Libre',
	CHAT: 'Conversation',
	EVENT: 'Evènements',
	CUSTOM_EVENT: 'Information',
	TYPE_EVENT: "Type d'évènements",
	ADD_CUSTOM_EVENT: 'Ajouter un évènement',
	AIR: 'Aérien',
	MARITIME: 'Maritime',
	RAILWAY: 'Féroviaire',
	MULTI: 'Multimodal',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'Prise en charge',
	HISTORY_OF: 'Historique de',
	HISTORY_NONE: "Pas d'historique",
	FILTER: 'Filtrer',
	ENDSCROLL: "Plus d'évènements",
	UNREAD_FILTER: 'Non lus',
	READ_FILTER: 'Lus',
	SHOW_FILTERS: 'Filtrer',
	STATUS_FILTER: 'Statut',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	HIDE_FILTERS: 'Masquer les filtres',
	LOADING: 'Chargement',
	FILES: 'Documents partagés',
	SELECT: 'Séléctionner',
	RESUME: 'Résumé',
	PURCHASES: 'Achats',
	PURCHASES_INFO: 'Informations sur les achats',
	CREATE_PDF: 'Créer PDF',
	DELIVERY_BILL: 'Bon de livraison',
	GENERATE_DELIVERY_BILL: 'Générer le bon de livraison',
	SHIPPER: 'Expéditeur',
	CONSIGNEE: 'Destinataire',
	PACKING_LIST: 'Liste de colisage',
	CUSTOMER_SOCIETY: 'Société du client',
	CUSTOMER_SURNAME: 'Prénom du client',
	CUSTOMER_NAME: 'Nom du client',
	CUSTOMER_PHONE: 'Téléphone du client',
	SHIPPER_SOCIETY: 'Société de livraison',
	SHIPPER_REMOVAL_ADDRESS: 'Addresse de ramassage',
	CONSIGNEE_SOCIETY: 'Société du destinataire',
	CONSIGNEE_ADDRESS: 'Addresse du destinataire',
	PARCEL_REFERENCE: 'Reference du colis',
	PICK_UP_CONTACT: "Contact à l'enlèvement",
	PICK_UP_NAME: 'Nom du contact',
	PICK_UP_SURNAME: 'Prénom du contact',
	PICK_UP_PHONE: 'Téléphone du contact',
	PICK_UP_DATE: 'Date du pick-up',
	PICK_UP_DESCRIPTION: 'Description du pick-up',
	PICK_UP_MAIL: 'Mail du contact',
	DELIVERY_CONTACT: 'Contact à la livraison',
	DELIVERY_NAME: 'Nom du contact',
	DELIVERY_SURNAME: 'Prénom du contact',
	DELIVERY_PHONE: 'Téléphone du contact',
	DELIVERY_ADDRESS: 'Addresse de livraison',
	DELIVERY_DESCRIPTION: 'Description de la livraison',
	DELIVERY_SOCIETY: 'Société de livraison',
	DELIVERY_MAIL: 'Mail du contact',
	DELETE_TRANSPORT: 'Supprimer transport',
	SERVICES: 'Prestations',
	PICK_UP: 'Pick-up',
	CUSTOMS: 'Douane',
	HANDLING: 'Manutention',
	FREIGHT: 'Freight',
	DELIVERY: 'Livraison',
	TAX: 'Taxe',
	SERVICE_PROVIDER: 'Prestataire',
	OTHER: 'Autres',
	TOTAL: 'Total',
	SHIPMENT_DETAILS: 'Détails du transport',
	COST_REVENUE: 'Coûts & Revenu',
	CARBON_FOOTPRINT: 'Empreinte carbone',
	TRANSPORT_REF: 'Référence Transport',
	STRACKER_REF: 'Référence Stracker',
	TAG: 'Tag',
	INCOTERM: 'Incoterm',
	DEPARTURE_ADDRESS: 'Adresse de départ',
	DEPARTURE_CITY: 'Ville de départ',
	DEPARTURE_COUNTRY: 'Pays de départ',
	STOPOVER: 'Adresse d’escale',
	NB_STOPOVER: "Nombres d'escale",
	ARRIVAL_ADDRESS: "Adresse d'arrivée",
	CHARGEABLE_WEIGHT: 'Poids chargeable',
	VOLUME: 'Volume',
	GROSS_WEIGHT: 'Poids net',
	UNITS_QUANTITY: "Quantité d'unités",
	UNITS_TYPE: "Type d'unités",
	UNITS_NB: "Nombre d'unités",
	PERFORMANCE: 'Performance',
	ETD: 'ETD',
	ETA: 'ETA',
	TRANSIT_TIME: 'Temps de transit',
	PRICE: 'Prix',
	COMMODITY_NATURE: 'Nature de marchandise',
	ARRIVAL_CITY: "Ville d'arrivée",
	ARRIVAL_COUNTRY: "Pays d'arrivée",
	DISTANCE: 'Distance',
	UNIT_GROSS_WEIGHT: 'Poids brut unitaire',
	UNIT_CHARGEABLE_WEIGHT: 'Poids chargeable unitaire',
	TOTAL_GROSS_WEIGHT: 'Poids brut total',
	TOTAL_CHARGEABLE_WEIGHT: 'Poids chargeable total',
	TRANSPORT_STATUS: 'Statut du transport',
	VALIDATION_DATE: 'Date de validation',
	QUOTE: 'Devis',
	SELL: 'Vente',
	PURCHASE: 'Achat',
	MARGIN: 'Marge',
	GENERAL_ACCOUNT_STATISTICS: 'Statistiques générales du compte',
	AVERAGE_TRANSPORT_PRICE: 'Prix moyen/transport',
	AVERAGE_TRANSIT_TIME: 'Transit time moyen',
	EVERY_REQUESTED_QUOTES: 'Nombre de cotation total demandées',
	EVERY_ACCEPTED_QUOTES: 'Nombre de cotation "acceptée”',
	EVERY_SUBMITTED_QUOTES: 'Nombre de cotation "en attente”',
	EVERY_PENDING_QUOTES: 'Nombre de cotation "devis”',
	EVERY_DECLINED_QUOTES: 'Nombre de cotation "refusé”',
	EVERY_READYTOSHIP_TRANSPORT: 'Nombre de transport "au départ”',
	EVERY_TRANSIT_TRANSPORT: 'Nombre de transport "en transit”',
	EVERY_ARRIVED_TRANSPORT: 'Nombre de transport "arrivé”',
	NB_SHARED_FILES: 'Nombre total de documents déposés',
	NB_ACTIVE_TRANSPORT: 'Nombre total de transport actif',
	NB_ARCHIVED_TRANSPORT: "Nombre de transport dans l'historique",
	NB_TOTAL_TRANSPORT: 'Nombre total de transport (actifs + historique)',
	EXPORT: 'Exporter',
	SALE: 'Vente',
	DETAILS: 'Détails',
	EXPORT_DATA: 'Export Excel',
	EXPORT_YOUR_DATA: 'Exportez vos données',
	WITHOUT_TAXES: 'Hors droit et taxes',
	PURCHASES_TOTAL: 'Achats total',
	SALES_TOTAL: 'Vente total',
	BUDGET: 'Budget',
	FLIGHT: 'Vol',
	NUMBER: 'Numéro',
	DATE: 'Date',
	AWB: 'LTA',
	PREFIX: 'Préfixe',
	SUFFIX: 'Suffixe',
	PLATE: 'Plaque',
	NAME: 'Nom',
	PHONENUMBER: 'Numéro de téléphone',
	DRIVER_INFORMATION: 'Informations du conducteur',
	DRIVER: 'Chauffeur',
	PHONE_ABV: 'Tel.',
	ADD_AIRPORT: 'Ajouter un Aéroport',
	NOT_SPECIFIED: 'Non-renseigné',
	UNRECOGNIZED_AIRLINE: 'Compagnie aérienne non reconnue',
	UNITS: 'unités',
	UNIT: 'unité',
	FROM_FILTER: 'Depuis le ',
	TO_FILTER: "Jusqu'au ",
	CLIENTS: 'Clients',
	CLIENT: 'Client',
	CAR: 'Routier',
	PLANE: 'Aérien',
	FILTER_BY: 'Filtrer par :',
	ORDER_BY: 'Trier par :',
	MAIL_SENT: 'Mail envoyé',
	ASK_FOR_RETURN: 'Demander un retour',
	ASK_TRANSPORT_RETURN: 'Demander un transport retour',
	ASK_TRANSPORT_RETURN_TEXT:
		'Changer les informations suivantes si elles sont erronées pour créer votre transport retour',
	START_ADDRESS: 'Adresse de départ',
	DESTINATION_ADDRESS: 'Adresse de destination',
	TYPE_ARRIVAL_DATE: "Rentrez une date d'arrivée",
	TYPE_DEPARTURE_DATE: 'Rentrez une date de départ',
	TRANSPORT_NOT_CREATED: "Le transport retour n'a pas été créé",
	INDOOR: 'Locatisation',
	ESTIMED_TIME: 'Temps estimé',
	INVOICE: "Facturation",
	GENERATE_INVOICE: "Générer une facture client",
	ADMIN_FEES: "Admin"
};

export default locale;
